
import { useRef} from "react";
import {useLoaderData, useLocation} from "react-router-dom";
import SEO from "../../Seo";
import {CohesiiContentComponent} from "../../lib";



const Page = () => {

    const pageRef = useRef(null)

    const location = useLocation();
    const {data} = useLoaderData()
    const {content, title, meta_title, meta_description} = data


    const seoTitle = meta_title ?? title
    const seoDesc = meta_description || "Welcome to Cohesii, where we connect space owners and seekers seamlessly. List your commercial spaces or find the perfect place to work, create, or gather with our intuitive platform"

    let crumbs = []
    if (location.pathname === '/home' || location.pathname === '/') {
        crumbs = [
            {
                'label': seoTitle,
                'path': process.env.REACT_APP_URL
            }
        ]
    }else {
        crumbs = [
            {
                'label': "Home",
                'path': process.env.REACT_APP_URL
            },
            {
                'label': seoTitle,
                'path': process.env.REACT_APP_URL + location.pathname
            },
        ]
    }


    return (
        <>

            <SEO
                title={seoTitle}
                description={seoDesc}
                url={process.env.REACT_APP_URL + location.pathname}
                canonical={location.pathname === '/home' ? process.env.REACT_APP_URL : process.env.REACT_APP_URL + location.pathname }
                image={process.env.REACT_APP_URL+'/images/cohesii.png'}
                imageAlt={'Cohesii strap line and logo  over a blue gradient overlay with a commercial space background.'}
                breadcrumbs={crumbs}
                pageType={"website"}
            />

            {
                content &&
                content.map((nested, index) => (
                    <CohesiiContentComponent order={index} ref={pageRef}
                                             key={nested.data._uid + index || index}
                                             content={nested}/>
                ))
            }

        </>
    )
}

export default Page