import './Space.css'
import {useLocation, useParams} from "react-router-dom";
import React, {useEffect, useRef} from "react";
import {useGetSpaceQuery} from "../../../app/services/spacesApi";
import MediaGallery from "./Gallery";
import Facilities from "./Facilities";
import SpaceContent from "./SpaceContent";
import {useModal} from "../../../lib/Modal/ModalContext";
import GallerySlider from "./GallerySlider";
import Solutions from "./Solutions/Solutions";
import Breadcrumbs from "../Breadcrumbs";
import SEO from "../../../Seo";
import MapView from "./Map";

const Space = ({isServer}) => {

    const galleryRef = useRef(null);
    const facilityRef = useRef(null);
    const { showModal } = useModal();
    const locationData = useLocation();

    const baseUrl = process.env.REACT_APP_STORAGE_URL;
    const {slug = ''} = useParams()

    const {data, error, isFetching, isLoading} = useGetSpaceQuery(slug)

    const {
        solutions = {},
        attributes = {},
        facilities={},
        timetables={},
        name,
        location
    } = data || {}

    useEffect(() => {

        if (!isFetching) {
            if (error && error['status'] === 404) {
                throw new Response("Not Found", { status: 404 });
            }
        }

    }, [data, error, isFetching]);


    const handleOpenModal = (index = 0) => {
        const component = <GallerySlider  baseUrl={baseUrl} images={data.media} startingIndex={index} />
        showModal(component);
    };

    const scrollToGal = () => {
        if (galleryRef.current) {
            galleryRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };


    const meta_description = (attributes?.description?.replace(/<\/?[^>]+(>|$)/g, "")) || "Cohesii Spaces that fit your life"
    return (
        <>
            {!isLoading && data && (
            <div className={'space'}>

                <SEO
                    title={name}
                    description={meta_description}
                    url={process.env.REACT_APP_URL + locationData.pathname}
                    canonical={process.env.REACT_APP_URL + locationData.pathname}
                    image={baseUrl+data.media[0] ??  process.env.REACT_APP_URL+'/images/cohesii.png'}
                    imageAlt={name}
                    breadcrumbs={[
                        {path: process.env.REACT_APP_URL, label: 'Home'},
                        {path: process.env.REACT_APP_URL+'/spaces/activities', label: 'Spaces'},
                        {path: process.env.REACT_APP_URL+ `/spaces/${slug}`, label: name},
                    ]}
                />

                <div className={'space__header'}>
                    <img loading={"lazy"} title={name} src={`${baseUrl}${data.media[0]}`}
                         className={'w-full max-h-[200px] md:max-h-[400px] object-cover object-center'} alt={name}/>
                </div>
                <div className={'space__body'}>
                    <div className={'container mt-6'}>
                        <Breadcrumbs crumbs={[
                            {path: '/', label: 'Home'},
                            {path: '/spaces/activities', label: 'Spaces'},
                            {path: `/spaces/${slug}`, label: name},
                        ]}/>
                    </div>
                    <div className={'row container'}>
                        <div className={'column py-[50px] min-w-[60%]'}>
                            <div className={'gallery relative flex'}>
                                <button onClick={scrollToGal} className={'btn-primary absolute top-[20px] left-[20px]'}>
                                    See full gallery
                                </button>
                                <div className={'row max-h-[500px] overflow-hidden'}>
                                    <div className={'column flex-1'}>
                                        <img title={name} src={`${baseUrl}${data.media[0]}`}
                                             className={'min-h-full  object-cover object-center rounded-md'}
                                             alt={name}/>
                                    </div>
                                    <div className={'column'}>
                                        <img title={name} src={`${baseUrl}${data.media[1]}`}
                                             className={'w-full max-h-[48%] flex-1 object-cover object-center rounded-md'}
                                             alt={data.name}/>
                                        <img title={name} src={`${baseUrl}${data.media[2]}`}
                                             className={'w-full max-h-[48%] flex-1 object-cover object-center rounded-md'}
                                             alt={name}/>
                                    </div>
                                </div>
                            </div>

                            <SpaceContent name={name}
                                          description={attributes?.description}
                                          location={location}
                                          timetables={timetables}
                                          how_to_access={attributes?.how_to_access}
                            />


                            {facilities.length > 0 && <Facilities facilities={facilities} facilityRef={facilityRef}/>}

                            <MediaGallery onClick={handleOpenModal} data={data} baseUrl={baseUrl}
                                          blockRef={galleryRef}/>


                        </div>
                        <div className={'column'}>
                            <Solutions solutions={solutions}/>
                        </div>
                    </div>
                </div>
                {!isServer &&
                    <>
                        ({data?.meta?.latitude && data?.meta?.longitude && (
                            <div className={'space__footer'}>
                                <MapView data={data}/>
                            </div>
                        )}
                    </>
                }
            </div>
            )}
        </>
    )
}

export default Space