import Loading from "../Loading";
import {Link, useLocation} from "react-router-dom";
import Breadcrumbs from "../Breadcrumbs";
import {useLoadActivityCats} from "../../../hooks/useBaseData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SEO from "../../../Seo";
import React from "react";

const Activities = () => {

    const {activityCats, isLoading}= useLoadActivityCats()
    const locationData = useLocation();

    const title = "All Space Activities"
    const meta_description = "Whether you're planning a business meeting, a memorable event, or need a creative studio, we have a variety of categories to help you easily find the space that suits you best"
    return (
        <>

            <SEO
                title={title}
                description={meta_description}
                url={process.env.REACT_APP_URL + locationData.pathname}
                canonical={process.env.REACT_APP_URL + locationData.pathname}
                image={process.env.REACT_APP_URL+'/images/cohesii.png'}
                imageAlt={title}
                breadcrumbs={[
                    {path: process.env.REACT_APP_URL, label: 'Home'},
                    {path: process.env.REACT_APP_URL+'/spaces/activities', label: 'All Space Activities'},
                ]}
            />


            <div className={'container py-8 pb-0'}>
                <Breadcrumbs crumbs={[
                    {path: process.env.REACT_APP_URL, label: 'Home'},
                    {path: process.env.REACT_APP_URL+'/spaces/activities', label: 'All Space Activities'},
                ]}/>
            </div>

            <div className={'container'}>

                <h1 className={'mt-[50px] title !text-3xl '}>All Activities</h1>
                <p className={'text-lg text-gray-500 mt-2'}>Welcome to our "All Activities" category list! Whether
                    you're planning a business meeting, a memorable event, or need a creative studio, we have a variety
                    of categories to help you easily find the space that suits you best. Explore our listings and
                    discover the perfect spot tailored to your unique needs.</p>
        <Loading isLoading={isLoading && !activityCats}/>

        {(!isLoading && activityCats) &&
            <div className={
                'activity_list py-[50px] pt-0'
            }>
            {activityCats.map((item, index) => (
                        <div className={'acitivity_parent'} key={'parent_' + item?.id}>
                            <h3 className={'mt-12 text-2xl text-gray-800 pb-4 border-b-[1px] border-blue-950 group'}>
                                <Link to={`/spaces/activities/${item.slug}`} className={'flex flex-row hover:text-blaze-500'}><span className={'flex-1'}> {item?.name}</span>
                                    <FontAwesomeIcon icon="fa-solid fa-arrow-right" className={"transition transform group-hover:animate-bounceX ml-2"} /></Link>
                            </h3>
                            <div className={'acitivity-child grid gap-6 grid-cols-5 mt-4'} key={'child' + item?.id}>
                                {item?.subcategories.map((child, index) => (
                                    <div className={'group'} key={'child_' + child?.id}>
                                        <Link to={`/spaces/activities/${child.slug}`} className={'group transition-all flex flex-col gap-2  p-4 hover:bg-gray-50 pointer-cursor rounded-md'}>
                                            <h4 className={'text-gray-800 text-lg group-hover:text-blaze-500'}> {child?.name}</h4>
                                            <p className={'text-sm text-gray-500'} dangerouslySetInnerHTML={{__html: child?.description}}></p>
                                        </Link>
                                    </div>

                                        ))
                                    }
                                </div>
                            </div>
                            ))
                            }
                        </div>
                    }
                </div>
        </>
    )
}

export default Activities;