import Legal from "../../Layouts/Legal";
import Banner from "../../Simple/Banner";
import SEO from "../../../Seo";
import {useLocation} from "react-router-dom";


const CookiePolicy = () => {

    const location = useLocation();
    const content = {
        title: 'Cookie Policy',
        content: "Understand our Cookie Policy at Cohesii LTD, detailing how we use cookies and similar technologies. Learn how to manage your cookie preferences and protect your privacy online.",
        "additional-content": [],
        asset: 'pexels-pixabay-39584 (1).jpg',
        _uid: "cookie-policy-header"
    }

    const title = "Cookie Policy "
    const meta_desc = "Understand our Cookie Policy at Cohesii LTD, detailing how we use cookies and similar technologies. Learn how to manage your cookie preferences and protect your privacy online."

    let crumbs = [
        {
            'label': "Home",
            'path': process.env.REACT_APP_URL
        },
        {
            'label': title,
            'path': process.env.REACT_APP_URL + location.pathname
        },
    ]

    return (
        <>
            <SEO
                title={title}
                description={meta_desc}
                url={process.env.REACT_APP_URL + location.pathname}
                canonical={process.env.REACT_APP_URL + location.pathname }
                image={process.env.REACT_APP_URL+'/images/cohesii.png'}
                imageAlt={'Cohesii strap line and logo over a blue gradient overlay with a commercial space background.'}
                breadcrumbs={crumbs}
            />

            <Banner content={content} />
            <Legal className={"order-2"}>
                <div data-custom-class="body">
                    <h2>Cookie Policy</h2>
                    <p>This Cookie Policy explains how Cohesii LTD ("Company," "we," "us," and "our") uses cookies and
                        similar technologies to recognize you when you visit our website at <a
                            href="https://cohesii.com" target="_blank" rel="noreferrer" >https://cohesii.com</a> ("Website"). It explains
                        what these technologies are and why we use them, as well as your rights to control our use of
                        them.</p>
                    <p>In some cases we may use cookies to collect personal information, or that becomes personal
                        information if we combine it with other information.</p>

                    <h3>What are cookies?</h3>
                    <p>Cookies are small data files that are placed on your computer or mobile device when you visit a
                        website. Cookies are widely used by website owners in order to make their websites work, or to
                        work more efficiently, as well as to provide reporting information.</p>
                    <p>Cookies set by the website owner (in this case, Cohesii LTD) are called "first-party cookies."
                        Cookies set by parties other than the website owner are called "third-party cookies."
                        Third-party cookies enable third-party features or functionality to be provided on or through
                        the website (e.g., advertising, interactive content, and analytics). The parties that set these
                        third-party cookies can recognize your computer both when it visits the website in question and
                        also when it visits certain other websites.</p>

                    <h3>Why do we use cookies?</h3>
                    <p>We use first- and third-party cookies for several reasons. Some cookies are required for
                        technical reasons in order for our Website to operate, and we refer to these as "essential" or
                        "strictly necessary" cookies. Other cookies also enable us to track and target the interests of
                        our users to enhance the experience on our Online Properties. Third parties serve cookies
                        through our Website for advertising, analytics, and other purposes. This is described in more
                        detail below.</p>

                    <h3>How can I control cookies?</h3>
                    <p>You have the right to decide whether to accept or reject cookies. You can exercise your cookie
                        rights by setting your preferences in the Cookie Consent Manager. The Cookie Consent Manager
                        allows you to select which categories of cookies you accept or reject. Essential cookies cannot
                        be rejected as they are strictly necessary to provide you with services.</p>
                    <p>The Cookie Consent Manager can be found in the notification banner and on our Website. If you
                        choose to reject cookies, you may still use our Website though your access to some functionality
                        and areas of our Website may be restricted. You may also set or amend your web browser controls
                        to accept or refuse cookies.</p>
                    <p>The specific types of first- and third-party cookies served through our Website and the purposes
                        they perform are described in the table below (please note that the specific cookies served may
                        vary depending on the specific Online Properties you visit):</p>

                    <h4>Analytics and customization cookies:</h4>
                    <p>These cookies collect information that is used either in aggregate form to help us understand how
                        our Website is being used or how effective our marketing campaigns are, or to help us customize
                        our Website for you.</p>

                    <table>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>_ga_#</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Purpose</td>
                            <td>Used to distinguish individual users by means of designation of a randomly generated
                                number as client identifier, which allows calculation of visits and sessions
                            </td>
                        </tr>
                        <tr>
                            <td>Provider</td>
                            <td>.cohesii.com</td>
                        </tr>
                        <tr>
                            <td>Service</td>
                            <td>Google Analytics <a href="https://business.safety.google/privacy/" target="_blank" rel="noreferrer" >View
                                Service Privacy Policy</a></td>
                        </tr>
                        <tr>
                            <td>Type</td>
                            <td>http_cookie</td>
                        </tr>
                        <tr>
                            <td>Expires in</td>
                            <td>1 year 1 month 4 days</td>
                        </tr>
                        </tbody>
                    </table>

                    <table>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>_ga</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Purpose</td>
                            <td>Records a particular ID used to come up with data about website usage by the user</td>
                        </tr>
                        <tr>
                            <td>Provider</td>
                            <td>.cohesii.com</td>
                        </tr>
                        <tr>
                            <td>Service</td>
                            <td>Google Analytics <a href="https://business.safety.google/privacy/" target="_blank" rel="noreferrer" >View
                                Service Privacy Policy</a></td>
                        </tr>
                        <tr>
                            <td>Type</td>
                            <td>http_cookie</td>
                        </tr>
                        <tr>
                            <td>Expires in</td>
                            <td>1 year 1 month 4 days</td>
                        </tr>
                        </tbody>
                    </table>

                    <h4>Unclassified cookies:</h4>
                    <p>These are cookies that have not yet been categorized. We are in the process of classifying these
                        cookies with the help of their providers.</p>

                    <table>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>klaviyoPagesVisitCount</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Provider</td>
                            <td>cohesii.com</td>
                        </tr>
                        <tr>
                            <td>Type</td>
                            <td>html_session_storage</td>
                        </tr>
                        <tr>
                            <td>Expires in</td>
                            <td>session</td>
                        </tr>
                        </tbody>
                    </table>

                    <table>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>klaviyoOnsite</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Provider</td>
                            <td>cohesii.com</td>
                        </tr>
                        <tr>
                            <td>Type</td>
                            <td>html_local_storage</td>
                        </tr>
                        <tr>
                            <td>Expires in</td>
                            <td>persistent</td>
                        </tr>
                        </tbody>
                    </table>

                    <table>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>__kla_id</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Provider</td>
                            <td>cohesii.com</td>
                        </tr>
                        <tr>
                            <td>Type</td>
                            <td>http_cookie</td>
                        </tr>
                        <tr>
                            <td>Expires in</td>
                            <td>1 year 1 month 4 days</td>
                        </tr>
                        </tbody>
                    </table>

                    <h3>How can I control cookies on my browser?</h3>
                    <p>As the means by which you can refuse cookies through your web browser controls vary from browser
                        to browser, you should visit your browser's help menu for more information. The following is
                        information about how to manage cookies on the most popular browsers:</p>

                    <ul>
                        <li><a href="https://support.google.com/chrome/answer/95647#zippy=%2Callow-or-block-cookies"
                               target="_blank" rel="noreferrer" >Chrome</a></li>
                        <li><a
                            href="https://support.microsoft.com/en-us/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                            target="_blank" rel="noreferrer" >Internet Explorer</a></li>
                        <li><a
                            href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                            target="_blank" rel="noreferrer" >Firefox</a></li>
                        <li><a href="https://support.apple.com/en-ie/guide/safari/sfri11471/mac"
                               target="_blank" rel="noreferrer" >Safari</a></li>
                        <li><a
                            href="https://support.microsoft.com/en-us/windows/microsoft-edge-browsing-data-and-privacy-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd"
                            target="_blank" rel="noreferrer" >Edge</a></li>
                        <li><a href="https://help.opera.com/en/latest/web-preferences/" target="_blank" rel="noreferrer" >Opera</a></li>
                    </ul>

                    <p>In addition, most advertising networks offer you a way to opt out of targeted advertising. If you
                        would like to find out more information, please visit:</p>
                    <ul>
                        <li><a href="http://www.aboutads.info" target="_blank" rel="noreferrer" >Digital Advertising Alliance</a></li>
                        <li><a href="http://www.networkadvertising.org" target="_blank" rel="noreferrer" >Digital Advertising Alliance of
                            Canada</a></li>
                        <li><a href="http://www.edaa.eu" target="_blank" rel="noreferrer" >European Interactive Digital Advertising
                            Alliance</a></li>
                    </ul>

                    <h3>What about other tracking technologies, like web beacons?</h3>
                    <p>Cookies are not the only way to recognize or track visitors to a website. We may use other,
                        similar technologies from time to time, like web beacons (sometimes called "tracking pixels" or
                        "clear gifs"). These are tiny graphics files that contain a unique identifier that enables us to
                        recognize when someone has visited our Website or opened an email including them. This allows
                        us, for example, to monitor the traffic patterns of users from one page within a website to
                        another, to deliver or communicate with cookies, to understand whether you have come to the
                        website from an online advertisement displayed on a third-party website, to improve site
                        performance, and to measure the success of email marketing campaigns. In many instances, these
                        technologies are reliant on cookies to function properly, and so declining cookies will impair
                        their functioning.</p>

                    <h3>Do you use Flash cookies or Local Shared Objects?</h3>
                    <p>Websites may also use so-called "Flash Cookies" (also known as Local Shared Objects or "LSOs")
                        to, among other things, collect and store information about your use of our services, fraud
                        prevention, and for other site operations.</p>
                    <p>If you do not want Flash Cookies stored on your computer, you can adjust the settings of your
                        Flash player to block Flash Cookies storage using the tools contained in the Website Storage
                        Settings Panel. You can also control Flash Cookies by going to the Global Storage Settings Panel
                        and following the instructions (which may include instructions that explain, for example, how to
                        delete existing Flash Cookies (referred to "information" on the Macromedia site), how to prevent
                        Flash LSOs from being placed on your computer without your being asked, and (for Flash Player 8
                        and later) how to block Flash Cookies that are not being delivered by the operator of the page
                        you are on at the time).</p>
                    <p>Please note that setting the Flash Player to restrict or limit acceptance of Flash Cookies may
                        reduce or impede the functionality of some Flash applications, including, potentially, Flash
                        applications used in connection with our services or online content.</p>

                    <h3>Do you serve targeted advertising?</h3>
                    <p>Third parties may serve cookies on your computer or mobile device to serve advertising through
                        our Website. These companies may use information about your visits to this and other websites in
                        order to provide relevant advertisements about goods and services that you may be interested in.
                        They may also employ technology that is used to measure the effectiveness of advertisements.
                        They can accomplish this by using cookies or web beacons to collect information about your
                        visits to this and other sites in order to provide relevant advertisements about goods and
                        services of potential interest to you. The information collected through this process does not
                        enable us or them to identify your name, contact details, or other details that directly
                        identify you unless you choose to provide these.</p>

                    <h3>How often will you update this Cookie Policy?</h3>
                    <p>We may update this Cookie Policy from time to time in order to reflect, for example, changes to
                        the cookies we use or for other operational, legal, or regulatory reasons. Please therefore
                        revisit this Cookie Policy regularly to stay informed about our use of cookies and related
                        technologies.</p>
                    <p>The date at the top of this Cookie Policy indicates when it was last updated.</p>

                    <h3>Where can I get further information?</h3>
                    <p>If you have any questions about our use of cookies or other technologies, please email us at <a
                        href="mailto:support@cohesii.com">support@cohesii.com</a> or by post to:</p>
                    <address>
                        <strong>Cohesii LTD</strong><br/>
                        20 Wenlock Rd,<br/>
                        London, <br/> N1 7GU,<br/>
                        UK<br/>
                        <strong>support@cohesii.com</strong>
                    </address>
                </div>

            </Legal>
        </>
    )
}

export default CookiePolicy