import React from 'react';
import {isRouteErrorResponse, useRouteError} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import NotFound from "./features/Components/Error/NotFound";
import Error from "./features/Components/Error/Error";


const  ErrorBoundary = () =>  {


    const error = useRouteError()


    if (isRouteErrorResponse(error)) {
        if (error.status === 404) {
            return (
                <>
                    <Helmet defer={false}
                        title={"404 Not found"}
                        titleTemplate={`Cohesii | %s`}
                    >
                        <meta name='description' content={"404 Page not found"} />
                        <meta name="robots" content={"noindex,nofollow"}/>
                    </Helmet>

                    <NotFound />
                </>
            );
        }

    }

    return (
        <>
            <Helmet defer={false}
                title={`${error.status} We ran into an error`}
                titleTemplate={`Cohesii | %s`}
            >
                <meta name='description' content={`${error.status} We ran into an error`}/>
                <meta name="robots" content={"noindex,nofollow"}/>
            </Helmet>
            <Error/>
        </>
    );
}

export default ErrorBoundary;