import Legal from "../../Layouts/Legal";
import Banner from "../../Simple/Banner";
import {useLocation} from "react-router-dom";
import SEO from "../../../Seo";


const PrivacyPolicy = () => {

    const location = useLocation();

    const content = {
        title: 'Privacy Policy',
        content: "Read our Privacy Policy to understand how we collect, use, and protect your personal information. Learn about your rights and how we ensure data privacy and security in compliance with applicable laws.",
        "additional-content": [],
        asset: 'pexels-pixabay-39584 (1).jpg',
        _uid: "privacy-policy-header"
    }

    const title = "Privacy Policy"
    const meta_desc = "Read our Privacy Policy to understand how we collect, use, and protect your personal information. Learn about your rights and how we ensure data privacy and security in compliance with applicable laws."

    let crumbs = [
        {
            'label': "Home",
            'path': process.env.REACT_APP_URL
        },
        {
            'label': title,
            'path': process.env.REACT_APP_URL + location.pathname
        },
    ]
    return (
        <>
            <SEO
                title={title}
                description={meta_desc}
                url={process.env.REACT_APP_URL + location.pathname}
                canonical={process.env.REACT_APP_URL + location.pathname }
                image={process.env.REACT_APP_URL+'/images/cohesii.png'}
                imageAlt={'Cohesii strap line and logo over a blue gradient overlay with a commercial space background.'}
                breadcrumbs={crumbs}
            />
            <Banner content={content} />
            <Legal className={"order-2"}>
                <div>
                    <p>This Privacy Notice for&nbsp;Cohesii LTD ('<strong>we</strong>', '<strong>us</strong>', or
                        '<strong>our</strong>'),
                        describes how and why we might access, collect, store, use, and/or share
                        ('<strong>process</strong>') your personal
                        information when you use our services ('<strong>Services</strong>'), including when you:</p>
                    <ul>
                        <li>Visit our website at&nbsp;<a href="https://cohesii.com" target="_blank" rel="noreferrer" 
                                                         >https://cohesii.com</a>,
                            or any website of ours that links to this Privacy Notice
                        </li>
                        <li>
                            <p>Engage with us in other related ways, including any sales, marketing, or events</p>
                        </li>
                    </ul>

                    <p><strong>Questions or concerns?&nbsp;</strong>Reading this Privacy Notice will help you understand
                        your privacy rights
                        and choices. We are responsible for making decisions about how your personal information is
                        processed. If you do not
                        agree with our policies and practices, please do not use our Services. If you still have any
                        questions or concerns,
                        please contact us at support@cohesii.com.</p>
                </div>
                <div id="summary">
                    <h3>SUMMARY OF KEY POINTS</h3>
                    <p><em><strong>This summary provides key points from our Privacy Notice, but you can find out more
                        details about any of
                        these topics by clicking the link following each key point or by using our&nbsp;</strong></em><a
                        href="#toc"><em><strong>table of contents</strong></em></a><em><strong>&nbsp;below to find the
                        section you are
                        looking for.</strong></em></p>
                    <p><strong>What personal information do we process?</strong> When you visit, use, or navigate our
                        Services, we may
                        process personal information depending on how you interact with us and the Services, the choices
                        you make, and the
                        products and features you use. Learn more about&nbsp;<a href="#personalinfo">personal
                            information you disclose to
                            us</a>.</p>
                    <p><strong>Do we process any sensitive personal information?&nbsp;</strong>Some of the information
                        may be considered
                        'special' or 'sensitive' in certain jurisdictions, for example your racial or ethnic origins,
                        sexual orientation,
                        and religious beliefs. We may process sensitive personal information when necessary with your
                        consent or as
                        otherwise permitted by applicable law. Learn more about&nbsp;<a href="#sensitiveinfo">sensitive
                            information we
                            process</a>.</p>
                    <p><strong>Do we collect any information from third parties?</strong> We do not collect any
                        information from third
                        parties.</p>
                    <p><strong>How do we process your information?</strong> We process your information to provide,
                        improve, and administer
                        our Services, communicate with you, for security and fraud prevention, and to comply with law.
                        We may also process
                        your information for other purposes with your consent. We process your information only when we
                        have a valid legal
                        reason to do so. Learn more about&nbsp;<a href="#infouse">how we process your information</a>.
                    </p>
                    <p><strong>In what situations and with which types of parties do we share personal
                        information?</strong> We may share
                        information in specific situations and with specific categories of third parties. Learn more
                        about&nbsp;<a
                            href="#whoshare">when and with whom we share your personal information</a>.</p>
                    <p><strong>How do we keep your information safe?</strong> We have adequate organizational and
                        technical processes and
                        procedures in place to protect your personal information. However, no electronic transmission
                        over the internet or
                        information storage technology can be guaranteed to be 100% secure, so we cannot promise or
                        guarantee that hackers,
                        cybercriminals, or other unauthorized third parties will not be able to defeat our security and
                        improperly collect,
                        access, steal, or modify your information. Learn more about&nbsp;<a href="#infosafe">how we keep
                            your information
                            safe</a>.</p>
                    <p><strong>What are your rights?</strong> Depending on where you are located geographically, the
                        applicable privacy law
                        may mean you have certain rights regarding your personal information. Learn more about&nbsp;<a
                            href="#privacyrights">your privacy rights</a>.</p>
                    <p><strong>How do you exercise your rights?</strong> The easiest way to exercise your rights is by
                        visiting <a
                            href="https://cohesii.com/contact" target="_blank" rel="noreferrer" 
                            >https://cohesii.com/contact</a>, or
                        by contacting us. We will consider and act upon any request in accordance with applicable data
                        protection laws.</p>
                    <p>Want to learn more about what we do with any information we collect?&nbsp;<a href="#toc">Review
                        the Privacy Notice in
                        full</a>.</p>
                </div>
                <div id="toc">
                    <h3>TABLE OF CONTENTS</h3>
                    <p><a href="#infocollect">1. WHAT INFORMATION DO WE COLLECT?</a></p>
                    <p><a href="#infouse">2. HOW DO WE PROCESS YOUR INFORMATION?</a></p>
                    <p><a href="#legalbases">3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR PERSONAL INFORMATION?</a>
                    </p>
                    <p><a href="#whoshare">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></p>
                    <p><a href="#cookies">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</a></p>
                    <p><a href="#ai">6. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?</a></p>
                    <p><a href="#sociallogins">7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</a></p>
                    <p><a href="#inforetain">8. HOW LONG DO WE KEEP YOUR INFORMATION?</a></p>
                    <p><a href="#infosafe">9. HOW DO WE KEEP YOUR INFORMATION SAFE?</a></p>
                    <p><a href="#infominors">10. DO WE COLLECT INFORMATION FROM MINORS?</a></p>
                    <p><a href="#privacyrights">11. WHAT ARE YOUR PRIVACY RIGHTS?</a></p>
                    <p><a href="#DNT">12. CONTROLS FOR DO-NOT-TRACK FEATURES</a></p>
                    <p><a href="#policyupdates">13. DO WE MAKE UPDATES TO THIS NOTICE?</a></p>
                    <p><a href="#contact">14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a></p>
                    <p><a href="#request">15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a>
                    </p>
                </div>
                <div id="infocollect">
                    <h2>1. WHAT INFORMATION DO WE COLLECT?</h2>

                    <h4>Personal information you disclose to us</h4>
                    <p><em><strong>In Short:&nbsp;</strong>We collect personal information that you provide for us.</em>
                    </p>
                    <p>We collect personal information that you voluntarily provide to us when you register on the
                        Services,&nbsp;express an
                        interest in obtaining information about us or our products and Services, when you participate in
                        activities on the
                        Services, or otherwise for when you contact us.</p>
                    <p><strong>Personal Information Provided by You.</strong> The personal information that we collect
                        depends on the
                        context of your interactions with us and the services, the choices you make, and the products
                        and features you use.
                        The personal information we collect may include the following:</p>
                    <ul>
                        <li>names</li>
                        <li>phone numbers</li>
                        <li>email addresses</li>
                        <li>mailing addresses</li>
                        <li>usernames</li>
                        <li>passwords</li>
                        <li>contact preferences</li>
                        <li>billing addresses</li>
                        <li>debit/credit card numbers</li>
                    </ul>
                    <p><strong>Sensitive Information.</strong> When necessary, with your consent or as otherwise
                        permitted by applicable
                        law, we process the following categories of sensitive information:</p>
                    <ul>
                        <li>data about a person's sex life or sexual orientation</li>
                    </ul>
                    <p><strong>Payment Data.</strong> We may collect data necessary to process your payment if you
                        choose to make purchases,
                        such as your payment instrument number, and the security code associated with your payment
                        instrument. All payment
                        data is handled and stored by Stripe. You may find their privacy notice link(s) here: <a
                            href="https://stripe.com/gb/privacy" target="_blank" rel="noreferrer" 
                            >https://stripe.com/gb/privacy</a>.</p>
                    <p><strong>Social Media Login Data.&nbsp;</strong>We may provide you with the option to register
                        with us using your
                        existing social media account details, like your Facebook, X, or other social media account. If
                        you choose to
                        register in this way, we will collect certain profile information about you from the social
                        media provider, as
                        described in the section called '<a href="#sociallogins">HOW DO WE HANDLE YOUR SOCIAL
                            LOGINS?</a>' below.</p>
                    <p>All personal information that you provide to us must be true, complete, and accurate, and you
                        must notify us of any
                        changes to such personal information.</p>
                    <p><strong>Information automatically collected</strong></p>
                    <p><em><strong>In Short:&nbsp;</strong>Some information &mdash; such as your Internet Protocol (IP)
                        address and/or
                        browser and device characteristics &mdash; is collected automatically when you visit our
                        Services.</em></p>
                    <p>We automatically collect certain information when you visit, use, or navigate the Services. This
                        information does not
                        reveal your specific identity (like your name or contact information) but may include device and
                        usage information,
                        such as your IP address, browser and device characteristics, operating system, language
                        preferences, referring URLs,
                        device name, country, location, information about how and when you use our Services, and other
                        technical
                        information. This information is primarily needed to maintain the security and operation of our
                        Services, and for
                        our internal analytics and reporting purposes.</p>
                    <p>Like many businesses, we also collect information through cookies and similar technologies.</p>
                    <p>The information we collect includes:</p>
                    <ul>
                        <li><em>Log and Usage Data.</em> Log and usage data is service-related, diagnostic, usage, and
                            performance
                            information our servers automatically collect when you access or use our Services and which
                            we record in log
                            files. Depending on how you interact with us, this log data may include your IP address,
                            device information,
                            browser type, and settings and information about your activity in the Services&nbsp;(such as
                            the date/time
                            stamps associated with your usage, pages and files viewed, searches, and other actions you
                            take such as which
                            features you use), device event information (such as system activity, error reports
                            (sometimes called 'crash
                            dumps'), and hardware settings).
                        </li>
                        <li><em>Device Data.</em> We collect device data such as information about your computer, phone,
                            tablet, or other
                            device you use to access the services. Depending on the device used, this device data may
                            include information
                            such as your IP address (or proxy server), device and application identification numbers,
                            location, browser
                            type, hardware model, Internet service provider and/or mobile carrier, operating system, and
                            system
                            configuration information.
                        </li>
                        <li><em>Location Data.</em> We collect location data such as information about your device's
                            location, which can be
                            either precise or imprecise. How much information we collect depends on the type and
                            settings of the device you
                            use to access the services. For example, we may use GPS and other technologies to collect
                            geolocation data that
                            tells us your current location (based on your IP address). You can opt out of allowing us to
                            collect this
                            information either by refusing access to the information or by disabling your Location
                            setting on your device.
                            However, if you choose to opt out, you may not be able to use certain aspects of the
                            services.
                        </li>
                    </ul>
                    <h4>Google API</h4>
                    <p>Our use of information received from Google APIs will adhere to&nbsp;<a
                        href="https://developers.google.com/terms/api-services-user-data-policy" target="_blank" rel="noreferrer" 
                        >Google API Services User Data Policy</a>, including the&nbsp;<a
                        href="https://developers.google.com/terms/api-services-user-data-policy#limited-use"
                        target="_blank" rel="noreferrer" 
                        >Limited Use requirements</a>.<br/>&nbsp;</p>
                </div>
                <div id="infouse">
                    <h2>2. HOW DO WE PROCESS YOUR INFORMATION?</h2>
                    <p><em><strong>In Short:&nbsp;</strong>We process your information to provide, improve, and
                        administer our Services,
                        communicate with you, for security and fraud prevention, and to comply with law. We may also
                        process your
                        information for other purposes with your consent.</em></p>
                    <p><strong>We process your personal information for a variety of reasons, depending on how you
                        interact with our
                        services, including:</strong></p>
                    <ul>
                        <li><strong>To facilitate account creation and authentication and otherwise manage user
                            accounts.&nbsp;</strong>We
                            can process your information so you can create and log in to your account, as well as keep
                            your account in
                            working order.
                        </li>
                        <li><strong>To deliver and facilitate delivery of services to the user.&nbsp;</strong>We may
                            process your
                            information to provide you with the requested service.
                        </li>
                        <li><strong>To respond to user inquiries/offer support to users.&nbsp;</strong>We may process
                            your information to
                            respond to your inquiries and solve any potential issues you might have with the requested
                            service.
                        </li>
                        <li><strong>To send administrative information to you.&nbsp;</strong>We may process your
                            information to send you
                            details about our products and services, changes to our terms and policies, and other
                            similar information.
                        </li>
                        <li><strong>To enable user-to-user communications.&nbsp;</strong>We may process your information
                            if you choose to
                            use any of our offerings that allow for communication with another user.
                        </li>
                        <li><strong>To deliver targeted advertising to you.</strong> We may process your information to
                            develop and display
                            personalised content and advertising tailored to your interests, location, and more.
                        </li>
                        <li><strong>To protect our Services.</strong> We may process your information as part of our
                            efforts to keep our
                            Services safe and secure, including fraud monitoring and prevention.
                        </li>
                        <li><strong>To identify usage trends.</strong> We may process information about how you use our
                            Services to better
                            understand how they are being used so we can improve them.
                        </li>
                        <li><strong>To save or protect an individual's vital interest.</strong> We may process your
                            information when
                            necessary to save or protect an individual&rsquo;s vital interest, such as to prevent harm.
                        </li>
                    </ul>
                </div>
                <div id="legalbases">
                    <h2>3. WHAT LEGAL BASES DO WE RELY ON TO PROCESS YOUR INFORMATION?</h2>
                    <p><em><strong>In Short:&nbsp;</strong>We only process your personal information when we believe it
                        is necessary and we
                        have a valid legal reason (i.e. legal basis) to do so under applicable law, like with your
                        consent, to comply with
                        laws, to provide you with services to enter into or fulfil our contractual obligations, to
                        protect your rights, or
                        to fulfil our legitimate business interests.</em></p>
                    <p>The General Data Protection Regulation (GDPR) and UK GDPR require us to explain the valid legal
                        bases we rely on in
                        order to process your personal information. As such, we may rely on the following legal bases to
                        process your
                        personal information:</p>
                    <ul>
                        <li><strong>Consent.&nbsp;</strong>We may process your information if you have given us
                            permission (i.e. consent) to
                            use your personal information for a specific purpose. You can withdraw your consent at any
                            time. Learn more
                            about&nbsp;<a href="#withdrawconsent">withdrawing your consent</a>.
                        </li>
                        <li><strong>Performance of a Contract.</strong> We may process your personal information when we
                            believe it is
                            necessary to fulfil our contractual obligations to you, including providing our Services or
                            at your request
                            prior to entering into a contract with you.
                        </li>
                        <li><strong>Legitimate Interests.</strong> We may process your information when we believe it is
                            reasonably
                            necessary to achieve our legitimate business interests and those interests do not outweigh
                            your interests and
                            fundamental rights and freedoms. For example, we may process your personal information for
                            some of the purposes
                            described in order to:
                        </li>
                        <li>
                            <p>Develop and display personalised and relevant advertising content for our users</p>
                        </li>
                        <li>
                            <p>Analyse how our Services are used so we can improve them to engage and retain users</p>
                        </li>
                        <li>
                            <p>Diagnose problems and/or prevent fraudulent activities</p>
                        </li>
                    </ul>
                    <ul>
                        <li><strong>Legal Obligations.</strong> We may process your information where we believe it is
                            necessary for
                            compliance with our legal obligations, such as to cooperate with a law enforcement body or
                            regulatory agency,
                            exercise or defend our legal rights, or disclose your information as evidence in litigation
                            in which we are
                            involved.
                        </li>
                        <li><strong>Vital Interests.</strong> We may process your information where we believe it is
                            necessary to protect
                            your vital interests or the vital interests of a third party, such as situations involving
                            potential threats to
                            the safety of any person.
                        </li>
                    </ul>
                </div>
                <div id="whoshare">
                    <h2>4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h2>
                    <p><em><strong>In Short:</strong>&nbsp;We may share information in specific situations described in
                        this section and/or
                        with the following categories of third parties.</em></p>
                    <p><strong>Vendors, Consultants, and Other Third-Party Service Providers.</strong> We may share your
                        data with
                        third-party vendors, service providers, contractors, or agents ('<strong>third parties</strong>')
                        who perform
                        services for us or on our behalf and require access to such information to do that work. We have
                        contracts in place
                        with our third parties, which are designed to help safeguard your personal information. This
                        means that they cannot
                        do anything with your personal information unless we have instructed them to do it. They will
                        also not share your
                        personal information with any organization apart from us. They also commit to protecting the
                        data they hold on our
                        behalf and to retain it for the period we instruct.</p>
                    <p>The categories of third party we may share personal information with are as follows:</p>
                    <ul>
                        <li>Data Analytics Services</li>
                        <li>Payment Processors</li>
                        <li>Social Networks</li>
                    </ul>
                    <p>We also may need to share your personal information in the following situations:</p>
                    <ul>
                        <li><strong>Business Transfers.</strong> We may share or transfer your information in connection
                            with, or during
                            negotiations of, any merger, sale of company assets, financing, or acquisition of all or a
                            portion of our
                            business to another company.
                        </li>
                        <li><strong>When we use Google Maps Platform APIs.</strong> We may share your information with
                            certain Google Maps
                            Platform APIs (e.g. Google Maps API, Places API). Google Maps uses GPS, Wi-Fi, and cell
                            towers to estimate your
                            location. GPS is accurate to about 20 meters, while Wi-Fi and cell towers help improve
                            accuracy when GPS signals
                            are weak, like indoors. This data helps Google Maps provide directions, but it is not always
                            perfectly precise.
                        </li>
                    </ul>
                </div>
                <div id="cookies">
                    <h2>5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h2>
                    <p><em><strong>In Short,</strong>&nbsp;We may use cookies and other tracking technologies to collect
                        and store your
                        information.</em></p>
                    <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to gather
                        information when you
                        interact with our services. Some online tracking technologies help us maintain the security of
                        our services and our
                        accounts, prevent crashes, fix bugs, save your preferences, and assist with basic site
                        functions.</p>
                    <p>We also permit third parties and service providers to use online tracking technologies in our
                        services for analytics
                        and advertising, including to help manage and display advertisements, to tailor advertisements
                        to your interests, or
                        to send abandoned shopping cart reminders (depending on your communication preferences). The
                        third parties and
                        service providers use their technology to provide advertising for products and services tailored
                        to your interests
                        which may appear either on our services or on other websites.</p>
                    <p>Specific information about how we use such technologies and how you can refuse certain cookies is
                        set out in our
                        Cookie Notice.</p>
                    <h4>Google Analytics</h4>
                    <p>We may share your information with Google Analytics to track and analyze the use of the Services.
                        The Google
                        Analytics Advertising Features that we may use include:&nbsp;Google Analytics Demographics and
                        Interests Reporting
                        and Remarketing with Google Analytics. To opt out of being tracked by Google Analytics across
                        the Services, visit <a
                            href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer" 
                            >https://tools.google.com/dlpage/gaoptout</a>.
                        You can opt out of Google Analytics Advertising Features through <a
                            href="https://adssettings.google.com/"
                            target="_blank" rel="noreferrer"  >Ads
                            Settings</a> and Ad Settings for mobile apps. Other opt out means include <a
                            href="http://optout.networkadvertising.org/" target="_blank" rel="noreferrer" 
                            >http://optout.networkadvertising.org/</a>
                        and <a href="http://www.networkadvertising.org/mobile-choice" target="_blank" rel="noreferrer" 
                               >http://www.networkadvertising.org/mobile-choice</a>.
                        For more information on the privacy practices of Google, please visit the <a
                            href="https://policies.google.com/privacy" target="_blank" rel="noreferrer"  >Google
                            Privacy &amp;
                            Terms page</a>.</p>
                </div>
                <div id="ai">
                    <h2>6. DO WE OFFER ARTIFICIAL INTELLIGENCE-BASED PRODUCTS?</h2>
                    <p><em><strong>In Short:</strong>&nbsp;We offer products, features, or tools powered by artificial
                        intelligence, machine
                        learning, or similar technologies.</em></p>
                    <p>As part of our Services, we offer products, features, or tools powered by artificial
                        intelligence, machine learning,
                        or similar technologies (collectively, 'AI Products'). These tools are designed to enhance your
                        experience and
                        provide you with innovative solutions. The terms in this Privacy Notice govern your use of the
                        AI Products within
                        our Services.</p>
                    <p><strong>How We Process Your Data Using AI</strong></p>
                    <p>All personal information processed using our AI Products is handled in line with our Privacy
                        Notice and our agreement
                        with third parties. This ensures high security and safeguards your personal information
                        throughout the process,
                        giving you peace of mind about your data's safety.</p>
                </div>
                <div id="sociallogins">
                    <h2>7. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h2>
                    <p><em><strong>In Short:&nbsp;</strong>If you choose to register or log in to our Services using a
                        social media account,
                        we may have access to certain information about you.</em></p>
                    <p>Our Services offer you the ability to register and log in using your third-party social media
                        account details (like
                        your Facebook or X logins). Where you choose to do this, we will receive certain profile
                        information about you from
                        your social media provider. The profile information we receive may vary depending on the social
                        media provider
                        concerned, but will often include your name, email address, friends list, and profile picture,
                        as well as other
                        information you choose to make public on such a social media platform.</p>
                    <p>We will use the information we receive only for the purposes that are described in this Privacy
                        Notice or that are
                        otherwise made clear to you on the relevant Services. Please note that we do not control, and
                        are not responsible
                        for, other uses of your personal information by your third-party social media provider. We
                        recommend that you review
                        their privacy notice to understand how they collect, use, and share your personal information,
                        and how you can set
                        your privacy preferences on their sites and apps.</p>
                </div>
                <div id="inforetain">
                    <h2>>8. HOW LONG DO WE KEEP YOUR INFORMATION?</h2>
                    <p><em><strong>In Short:&nbsp;</strong>We keep your information for as long as necessary to fulfil
                        the purposes outlined
                        in this Privacy Notice unless otherwise required by law.</em></p>
                    <p>We will only keep your personal information for as long as it is necessary for the purposes set
                        out in this Privacy
                        Notice, unless a longer retention period is required or permitted by law (such as tax,
                        accounting, or other legal
                        requirements). No purpose in this notice will require us keeping your personal information for
                        longer than&nbsp;twenty
                        four (24) months past the termination of the user's account.</p>
                    <p>When we have no ongoing legitimate business need to process your personal information, we will
                        either delete or
                        anonymise such information, or, if this is not possible (for example, because your personal
                        information has been
                        stored in backup archives), then we will securely store your personal information and isolate it
                        from any further
                        processing until deletion is possible.</p>
                </div>
                <div id="infosafe">
                    <h2>9. HOW DO WE KEEP YOUR INFORMATION SAFE?</h2>
                    <p><em><strong>In Short:&nbsp;</strong>We aim to protect your personal information through a system
                        of organizational
                        and technical security measures.</em></p>
                    <p>We have implemented appropriate and reasonable technical and organizational security measures
                        designed to protect the
                        security of any personal information we process. However, despite our safeguards and efforts to
                        secure your
                        information, no electronic transmission over the Internet or information storage technology can
                        be guaranteed to be
                        100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other
                        unauthorized third parties
                        will not be able to defeat our security and improperly collect, access, steal, or modify your
                        information. Although
                        we will do our best to protect your personal information, transmission of personal information
                        to and from our
                        services is at your own risk. You should only access the services within a secure
                        environment.</p>
                </div>
                <div id="infominors">
                    <h2>10. DO WE COLLECT INFORMATION FROM MINORS?</h2>
                    <p><em><strong>In Short:</strong>&nbsp;We do not knowingly collect data from or market to children
                        under 18 years of
                        age.</em></p>
                    <p>We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor
                        do we knowingly sell
                        such personal information. By using the Services, you represent that you are at least 18 or that
                        you are the parent
                        or guardian of such a minor and consent to such minor dependent&rsquo;s use of the Services. If
                        we learn that
                        personal information from users less than 18 years of age has been collected, we will deactivate
                        the account and
                        take reasonable measures to promptly delete such data from our records. If you become aware of
                        any data we may have
                        collected from children under age 18, please contact us at <a
                            href="mailto:suppoer@cohesii.com">support@cohesii.com</a>.</p>
                </div>
                <div id="privacyrights">
                    <h2>11. WHAT ARE YOUR PRIVACY RIGHTS?</h2>
                    <p><em><strong>In Short:</strong>&nbsp;In some regions, such as the European Economic Area (EEA),
                        United Kingdom (UK),
                        and Switzerland, you have rights that allow you greater access to and control over your personal
                        information.&nbsp;You
                        may review, change, or terminate your account at any time, depending on your country, province,
                        or state of
                        residence.</em></p>
                    <p>In some regions (like the EEA, UK, and Switzerland), you have certain rights under applicable
                        data protection laws.
                        These may include the right (i) to request access and obtain a copy of your personal
                        information, (ii) to request
                        rectification or erasure; (iii) to restrict the processing of your personal information; (iv) if
                        applicable, to data
                        portability; and (v) not to be subject to automated decision-making. In certain circumstances,
                        you may also have the
                        right to object to the processing of your personal information. You can make such a request by
                        contacting us by
                        using the contact details provided in the section '<a href="#contact">HOW CAN YOU CONTACT US
                            ABOUT THIS NOTICE?</a>'
                        below.</p>
                    <p>We will consider and act upon any request in accordance with applicable data protection laws.</p>
                    <p>If you are located in the EEA or UK and you believe we are unlawfully processing your personal
                        information, you also
                        have the right to complain to your <a
                            href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                            target="_blank" rel="noreferrer" 
                            >Member State data protection authority</a> or&nbsp;<a
                            href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                            target="_blank" rel="noreferrer"  >UK data protection authority</a>.</p>
                    <p>If you are located in Switzerland, you may contact the <a
                        href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                        target="_blank" rel="noreferrer"  >Federal Data
                        Protection and Information Commissioner</a>.</p>
                    <p><strong><u>Withdrawing your consent:</u></strong> If we are relying on your consent to process
                        your personal
                        information, you have the right to withdraw your consent at any time. You can withdraw your
                        consent at any time by
                        contacting us by using the contact details provided in the section '<a href="#contact">HOW CAN
                            YOU CONTACT US ABOUT
                            THIS NOTICE?</a>' below or updating your preferences.</p>
                    <p>However, please note that this will not affect the lawfulness of the processing before its
                        withdrawal nor, will it
                        affect the processing of your personal information conducted in reliance on lawful processing
                        grounds other than
                        consent.</p>
                    <p><strong><u>Opting out of marketing and promotional communications:</u>&nbsp;</strong>You can
                        unsubscribe from our
                        marketing and promotional communications at any time by clicking on the unsubscribe link in the
                        emails that we send,
                        replying 'STOP' or 'UNSUBSCRIBE' to the SMS messages that we send, or by contacting us using the
                        details provided in
                        the section '<a href="#contact">HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>' below. You will
                        then be removed from
                        the marketing lists. However, we may still communicate with you &mdash; for example, to send you
                        service-related
                        messages that are necessary for the administration and use of your account, to respond to
                        service requests, or for
                        other non-marketing purposes.</p>
                    <h4>Account Information</h4>
                    <p>If you would at any time like to review or change the information in your account or terminate
                        your account, you
                        can:</p>
                    <ul>
                        <li>Log in to your account settings and update your user account.</li>
                    </ul>
                    <p>Upon your request to terminate your account, we will deactivate or delete your account and
                        information from our
                        active databases. However, we may retain some information in our files to prevent fraud,
                        troubleshoot problems,
                        assist with any investigations, enforce our legal terms and/or comply with applicable legal
                        requirements.</p>
                    <p><strong><u>Cookies and similar technologies:</u></strong> Most Web browsers are set to accept
                        cookies by default. If
                        you prefer, you can usually choose to set your browser to remove cookies and to reject cookies.
                        If you choose to
                        remove cookies or reject cookies, this could affect certain features or services of our
                        Services.</p>
                    <p>If you have questions or comments about your privacy rights, you may email us at
                        support@cohesii.com.</p>
                </div>
                <div id="DNT">
                    <h2>12. CONTROLS FOR DO-NOT-TRACK FEATURES</h2>
                    <p>Most web browsers and some mobile operating systems and mobile applications include a
                        Do-Not-Track ('DNT') feature or
                        setting you can activate to signal your privacy preference not to have data about your online
                        browsing activities
                        monitored and collected. At this stage, no uniform technology standard for recognising and
                        implementing DNT signals
                        has been finalised. As such, we do not currently respond to DNT browser signals or any other
                        mechanism that
                        automatically communicates your choice not to be tracked online. If a standard for online
                        tracking is adopted that
                        we must follow in the future, we will inform you about that practice in a revised version of
                        this Privacy
                        Notice.</p>
                </div>
                <div id="policyupdates">
                    <h2>13. DO WE MAKE UPDATES TO THIS NOTICE?</h2>
                    <p><em><strong>In Short:&nbsp;</strong>Yes, we will update this notice as necessary to stay
                        compliant with relevant
                        laws.</em></p>
                    <p>We may update this Privacy Notice from time to time. The updated version will be indicated by an
                        updated 'Revised'
                        date at the top of this Privacy Notice. If we make material changes to this Privacy Notice, we
                        may notify you either
                        by prominently posting a notice of such changes or by directly sending you a notification. We
                        encourage you to
                        review this Privacy Notice frequently to be informed of how we are protecting your
                        information.</p>
                </div>
                <div id="contact">
                    <h2>14. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h2>
                    <p>If you have questions or comments about this notice, you may email us at support@cohesii.com
                        or&nbsp;contact us by
                        post at:</p>
                    <address>
                        Cohesii LTD<br/>
                        20 Wenlock Rd,<br/>
                        London&nbsp;<br/>
                        N1 7GU, UK
                    </address>
                </div>
                <div id="request">
                    <h2>15. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h2>
                    <p>You have the right to request access to the personal information we collect from you, details
                        about how we have
                        processed it, correct inaccuracies, or delete your personal information. You may also have the
                        right to withdraw
                        your consent to our processing of your personal information. These rights may be limited in some
                        circumstances by
                        applicable law. To request to review, update, or delete your personal information, please
                        visit: <a
                            href="https://cohesii.com/contact" target="_blank" rel="noreferrer" 
                            >https://cohesii.com/contact</a>.
                    </p>
                </div>
            </Legal>
        </>
    )
}

export default PrivacyPolicy