import {Link, useLoaderData, useLocation} from "react-router-dom";
import React from "react";
import Breadcrumbs from "../Breadcrumbs";
import SearchComponent from "./SearchForm";
import ListItem from "./ListItem";
import SEO from "../../../Seo";

const Category = () => {

    const baseUrl = process.env.REACT_APP_STORAGE_URL;

    const data = useLoaderData()
    const locationData = useLocation();

    const {name, description, slug, image_path, id, parent, "latest-spaces": latestSpaces} = data || {"latest-spaces":[]}


    const title = `Find ${name.toLowerCase()} spaces`
    const meta_description = description

    return (
        <div className={'space__category_page'}>


            <SEO
                title={title}
                description={meta_description}
                url={process.env.REACT_APP_URL + locationData.pathname}
                canonical={process.env.REACT_APP_URL + locationData.pathname}
                image={baseUrl+image_path ??  process.env.REACT_APP_URL+'/images/cohesii.png'}
                imageAlt={name}
                breadcrumbs={[
                    {path: process.env.REACT_APP_URL, label: 'Home'},
                    {path: process.env.REACT_APP_URL+'/spaces/activities', label: 'All Space Activities'},
                    {path: process.env.REACT_APP_URL+`/spaces/activities/${parent?.slug}`, label: parent?.name || null },
                    {path: process.env.REACT_APP_URL+ `/spaces/activities/${slug}`, label: name},
                ]}
            />

            <section className={'space__category_page__header'}>
                <div className={'container row'}>
                    <div className={'column justify-center'}>
                        <h2 className={'title !text-3xl'}>Find {name.toLowerCase()} spaces near me </h2>
                        <p className={'text-gray-500 text-lg'}>{description}</p>

                        <SearchComponent defaultActivityValue={id} />
                    </div>
                    <div className={'column'}>
                        <figure>
                            <img className={'rounded-lg'} src={baseUrl+image_path} alt={description} title={name} />
                        </figure>
                    </div>
                </div>
            </section>
            <section className={'bg-blue-950 text-white'}>
                <div className={'container mb-6'}>
                    <Breadcrumbs textColor={'light'} crumbs={[
                        {path: process.env.REACT_APP_URL, label: 'Home'},
                        {path: process.env.REACT_APP_URL+'/spaces/activities', label: 'All Space Activities'},
                        {path: process.env.REACT_APP_URL+`/spaces/activities/${parent?.slug}`, label: parent?.name || null },
                        {path: process.env.REACT_APP_URL+ `/spaces/activities/${slug}`, label: name},
                    ]}/>
                </div>

                <div className={'container'}>
                    <h1 className={'text-blue-100 text-2xl lg:text-4xl font-bold'}>
                        {name} spaces on Cohesii
                    </h1>
                </div>
            </section>
            <section className={'py-[50px]'}>
                <div className={'container mb-12'}>
                    <h3 className={'title !text-4xl text-center'}>Latest Spaces</h3>
                </div>
                <div className={'container grid grid-cols-4 gap-4'}>
                    {latestSpaces && latestSpaces.map((item, index) => (
                        <ListItem baseUrl={baseUrl} space={item} key={'latest_space_' + index} />
                    ))}

                    {latestSpaces.length < 1 && (
                        <div className={'comming-soon col-span-4 flex flex-col gap-4'}>
                            <h3 className={'text-2xl font-bold text-blaze-500 font-montserrat'}>
                                Stay Tuned! We are onboarding new spaces.</h3>
                            <p>We’re constantly working hard to bring you new spaces so come back soon to find your next adventure.</p>

                            <p>In the meantime, feel free to explore other sections of our marketplace, and remember, new
                                spaces are just around the corner!</p>

                            <Link to={'/'} className={'btn-primary'}>
                                Back to home
                            </Link>
                        </div>
                    )}
                </div>
            </section>


        </div>
    )
}

export default Category;