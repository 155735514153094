import Legal from "../../Layouts/Legal";
import Banner from "../../Simple/Banner";
import SEO from "../../../Seo";
import {useLocation} from "react-router-dom";


const ModernSlaveryPolicy = () => {

    const location = useLocation();
    const content = {
        title: 'Modern Slavery Policy',
        content: "Cohesii LTD is committed to preventing modern slavery and human trafficking in our operations and supply chain. Learn more about our policies, employee training, and reporting procedures to uphold ethical business practices.",
        "additional-content": [],
        asset: 'pexels-pixabay-39584 (1).jpg',
        _uid: "cookie-policy-header"
    }

    const title = "Modern Slavery Policy"
    const meta_desc = "Cohesii LTD is committed to preventing modern slavery and human trafficking in our operations and supply chain. Learn more about our policies, employee training, and reporting procedures to uphold ethical business practices."
    let crumbs = [
        {
            'label': "Home",
            'path': process.env.REACT_APP_URL
        },
        {
            'label': title,
            'path': process.env.REACT_APP_URL + location.pathname
        },
    ]

    return (
        <>
            <SEO
                title={title}
                description={meta_desc}
                url={process.env.REACT_APP_URL + location.pathname}
                canonical={process.env.REACT_APP_URL + location.pathname }
                image={process.env.REACT_APP_URL+'/images/cohesii.png'}
                imageAlt={'Cohesii strap line and logo over a blue gradient overlay with a commercial space background.'}
                breadcrumbs={crumbs}
            />

            <Banner content={content} />
            <Legal className={"order-2"}>
                <h2 className="text-2xl font-semibold">Overview</h2>
                <p><strong>Cohesii LTD</strong> is dedicated to preventing modern slavery and human trafficking in our operations and
                    supply chain. We comply with the Modern Slavery Act 2015 and strive to ensure that our business
                    practices uphold human rights.</p>

                <h2 className="text-2xl font-semibold">Our Commitment</h2>
                <ul className="list-disc ml-6">
                    <li><strong>Zero Tolerance:</strong> We have a zero-tolerance approach to modern slavery and human
                        trafficking.
                    </li>
                    <li><strong>Responsible Sourcing:</strong> We conduct thorough due diligence to identify and
                        mitigate risks in our supply chain.
                    </li>
                    <li><strong>Employee Awareness:</strong> We provide training to our employees about recognizing and
                        addressing modern slavery.
                    </li>
                </ul>

                <h2 className="text-2xl font-semibold">What is Modern Slavery?</h2>
                <p>Modern slavery includes:</p>
                <ul className="list-disc ml-6">
                    <li><strong>Slavery:</strong> The ownership of people.</li>
                    <li><strong>Servitude:</strong> The obligation to provide services for little or no pay.</li>
                    <li><strong>Forced Labor:</strong> Compulsion to work against one's will.</li>
                    <li><strong>Human Trafficking:</strong> The illegal trade of people for exploitation.</li>
                </ul>

                <h2 className="text-2xl font-semibold">Reporting Concerns</h2>
                <p>If you suspect any instances of modern slavery within our operations or supply chain, please contact
                    us immediately:</p>
                <ul className="list-none ml-6">
                    <li><strong>Email:</strong> <a href="mailto:support@cohesii.com"
                                                   className="text-blue-500">support@cohesii.com</a></li>
                </ul>
                <p>We take all reports seriously and will investigate promptly.</p>

                <h2 className="text-2xl font-semibold">Due Diligence Process</h2>
                <p>We continuously assess our supply chain for risks related to modern slavery and conduct audits to
                    ensure compliance with our ethical standards.</p>

                <h2 className="text-2xl font-semibold">Training</h2>
                <p>We provide regular training to our employees on:</p>
                <ul className="list-disc ml-6">
                    <li>Understanding modern slavery</li>
                    <li>Identifying signs of exploitation</li>
                    <li>Reporting procedures</li>
                </ul>

                <h2 className="text-2xl font-semibold">Policy Review</h2>
                <p>We review our Modern Slavery Policy annually to ensure its effectiveness and compliance with legal
                    requirements.</p>
                <p>For more information about our commitment to ethical business practices, please contact us at <a
                    href="mailto:support@cohesii.com" className="text-blue-500">support@cohesii.com</a>.</p>
            </Legal>
        </>
    )
}

export default ModernSlaveryPolicy